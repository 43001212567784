import dayjs from 'dayjs';
import React from "react"
import { Helmet } from 'react-helmet';

export default ({ date }) => {
  const formattedDate = dayjs(date).format('MM.DD.YYYY');

  let title = 'All Souls Advent';
  if (date) {
    title = `All Souls Advent | ${formattedDate}`;
  }

  return (
    <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-BFKNMMGS3G"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-BFKNMMGS3G');
        `}
      </script>
      <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:image" content="/share.jpg" />
    </Helmet>
  );
};

import dayjs from 'dayjs';
import React from "react"

const defaultDate = '27 NOVEMBER — 25 DECEMBER 2020';

export default ({ date }) => {
  return (
    <div className="service-title">
      <a href="/">
        <h3 className="service-title--title">ADVENT DAILY WORSHIP</h3>
        <h3 className="service-title--date">{dayjs(date).format('D MMMM YYYY')}</h3>
      </a>

      <div className="service-title--links">
        <a
          className="podcast-link"
          href="https://allsoulsseattle.buzzsprout.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h3 className="service-title--podcast">Listen to the Podcast</h3>
          <img alt="click to listen" className="nav-img" src="/podcast.png" />
        </a>

        <a
          className="logo noborder"
          href="https://www.allsoulsseattle.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img alt="All Souls" src="/logo.png" />
        </a>
      </div>
    </div>
  );
};

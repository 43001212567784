import React from "react"

import '../styles/main.scss';
import Head from '../components/head';
import Header from '../components/header';
import Loader from '../components/loader';

export default ({ children, className, date }) => {
  return (
    <div className={className}>
      <Head date={date} />
      <Loader />

      <Header audioUrl={null} date={date} />

      {children}
    </div>
  );
};
